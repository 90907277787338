import Notification from "./Notification";

const data = {
    appList: [
        {
            name: "startups-edu-web-prod",
            entry: "https://www.startups168.com/startups-edu-web-prod/",
            container: "#container",
            activeRule: "#/edu/",
            props: {
                Notification: Notification,
            },
        },
        // {
        //     name: "startups-data-web-prod",
        //     entry: "https://www.startups168.com/startups-data-web-prod/",
        //     container: "#container",
        //     activeRule: "#/data/",
        //     props: {
        //         Notification: Notification,
        //     },
        // },
        {
            name: "startups-web-prod",
            entry: "https://www.startups168.com/startups-web-prod/",
            container: "#container",
            activeRule: (location) => {
                if (location.hash.startsWith("#/edu/")) {
                    return false;
                } else {
                    return true;
                }
            },
            props: {
                Notification: Notification,
            },
        },
    ],
};

export default data;
