import React from "react";
import {createRoot} from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {registerMicroApps, start} from "qiankun";

import Application from "./common/Application";

import MainIndex from "./view/main/Index";

import "./index.css";

document.title = "创石-让生意更简单";

registerMicroApps(Application.appList);
start();

const root = createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <div id="container"></div>
        <MainIndex />
    </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
