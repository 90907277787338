import React, {useState, useEffect} from "react";

import Notification from "../../common/Notification";

import Style from "./Index.module.css";

const MainIndex = (props) => {
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        setIsLoad(true);

        Notification.on("main_loading_active", this, (data) => {
            setIsLoad(data.isLoad);
        });

        return () => {
            Notification.remove("main_loading_active", this);
        };

        //eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            {isLoad ? (
                <div className={Style.loading}>
                    <div className={Style.loading_icon}></div>
                </div>
            ) : null}
        </React.Fragment>
    );
};

MainIndex.propTypes = {};

MainIndex.defaultProps = {};

export default MainIndex;
